.logo {
  position: relative;
  top: -40px;
  width: 140px;
  left: 50%;
  transform: translateX(-50%);
}

.logout {
  position: absolute;
  height: 60px;
  width: 60px;
  border-radius: 50px;
  border: 0;
  padding: 10px;
  background: rgba(255, 214, 140, 0.5);
  top: 5px;
  right: 10px;

  &:hover {
    background: rgba(255, 214, 140, 0.75);
  }
  &:active {
    background: rgba(255, 214, 140, 1);
  }

  img {
    height: 100%;
  }
}

.energyBox {
  position: absolute;
  top: 5px;
  background: linear-gradient(
    180deg,
    rgba(255, 214, 140, 0.5),
    rgba(183, 145, 100, 0.8)
  );
  padding: 8px 20px;
  border-radius: 16px;
  border: 1px solid #977b5f;
  font-size: 32px;
  right: 140px;

  img {
    height: 40px;
    margin-right: 10px;
    vertical-align: middle;
  }
}
