.input {
  position: relative;

  .wrapper {
    height: 48px;
    width: 100%;
    position: relative;

    > input {
      position: absolute;
      width: 100%;
      outline: 0;
      border: 2px solid #fdd4a0;
      border-radius: 12px;
      height: 100%;
      box-sizing: border-box;
      font-family: "secular one", sans-serif;
      padding: 21px 8px 2px;
      line-height: 24px;
      color: #333333;
      transition: all 200ms;

      &.error {
        border-color: #ff0000 !important;
      }

      &:focus,
      &:valid {
        border-color: #977b5f;
        box-shadow: inset 0 0 2px 0px #503c27;
      }

      & + label {
        position: absolute;
        line-height: 20px;
        font-size: 18px;
        padding: 14px 14px 0;
        color: rgba(0, 0, 0, 0.6);
        transition: all 200ms;
        top: 0;
      }

      &:focus + label,
      &:valid + label {
        top: -10px;
        padding: 14px 10px 0;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.8);
      }
    }
  }

  &.passwordField {
    .wrapper {
      > input {
        padding: 21px 60px 2px 8px;
      }
    }
  }

  .errorMsg {
    color: #f00;
    background: #fff6;
    font-weight: 600;
    padding: 2px 5px;
    border-radius: 4px;
    margin-bottom: 2px;
    pointer-events: none;
  }
}

.showPass {
  position: absolute;
  z-index: 1;
  right: 6px;
  font-size: 13px;
  color: #4a341b;
  border-radius: 20px;

  padding: 4px 10px;
  top: 11px;
  font-weight: 600;

  &:hover {
    background: rgba(253, 212, 160, 0.6);
  }
}
