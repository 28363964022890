.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  text-align: center;
  width: 1200px;
  padding: 20px 60px;

  p,
  div {
    text-align: left;

    img {
      vertical-align: top;
    }
  }

  button {
    div {
      text-align: center;
    }
  }
}

.hide {
  right: 0;
}

.close {
  position: absolute;
  width: 200px;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}
