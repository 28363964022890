.container {
  width: 100%;
}

.wrapper {
  padding: 5px 10px;
}

.statHeader {
  display: flex;
  padding-right: 80px;
  opacity: 0;
  transition: 0.25s opacity 0.1s;
  position: relative;

  &.withArrows {
    padding-left: 40px;
  }

  .showDetails & {
    opacity: 1;
  }

  > img {
    max-width: 240px;
  }
}

.nameContainer {
  font-size: 22px;

  img {
    margin-right: 5px;
    width: 22px;
    vertical-align: middle;
  }
}

.favorite {
  height: 30px;
  width: 32px;
  background: url("~/public/images/ui/star-empty.png") no-repeat;
  background-size: cover;
  opacity: 0.4;
  display: inline-block;
  filter: invert(1) brightness(2);
  margin-right: 14px;
  vertical-align: bottom;

  &.selected {
    background: url("~/public/images/ui/star-full.png") no-repeat;
    opacity: 1;
    filter: none;
  }
}

.close {
  border: 0;
  margin: 0;
  padding: 0;
  background: transparent;
  color: #ccc;
  position: absolute;
  top: 5px;
  right: 15px;
  height: 64px;
  width: 64px;
  cursor: pointer;

  svg {
    height: 100%;
    width: 100%;
  }
}

.statsWrapper {
  flex: 1;
}

.stats {
  background: #ffebd1;
  border-radius: 8px;
  color: #503c27;
  -webkit-text-stroke: 0;
  text-shadow: none;
  width: 100%;
  padding: 2px 6px;
  box-sizing: border-box;
  margin-top: 20px;
}

.statLine {
  display: flex;
  margin: 3px 0;

  .statLabel {
    width: 80px;
  }

  .statBar {
    flex: 1;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.4);
    position: relative;
    overflow: hidden;

    .fill {
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgb(0 117 221 / 75%);
      height: 100%;
      border-radius: 0 4px 4px 0;
      overflow: hidden;
      opacity: 0;
      width: 0%;
      transition: 0.5s cubic-bezier(0.61, 1.56, 0.62, 0.9) width 0.3s,
        0.75s opacity 0.3s;

      .modifier {
        position: absolute;
        height: 100%;
        top: 0;
        right: 0;

        &.green {
          background: linear-gradient(90deg, transparent, #2fbf2f 40%);
        }

        &.red {
          background: linear-gradient(90deg, transparent, #cb1a1a 40%);
          min-width: 7.5%;
        }
      }
    }
  }
}

.abilitiesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 20px;
}

.ability {
  width: 49.5%;
  display: flex;
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 0.75s cubic-bezier(0.61, 1.56, 0.62, 0.9),
    transform 0.3s cubic-bezier(0.61, 1.56, 0.62, 0.9);

  .showDetails & {
    opacity: 1;
    transform: translateX(0);
  }

  > img {
    width: 144px;
    border-radius: 16px;
  }

  .description {
    margin-left: 10px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 6px 4px;

    .partName {
      color: #ffebd1;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    .cardName {
      font-family: "Luckiest Guy", sans-serif;
      font-size: 20px;
      -webkit-text-stroke: 1px #423a32;
    }

    > p {
      margin: 2px 0 0;
      font-size: 15px;
    }

    .cast {
      margin-top: auto;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
    }

    .mana {
      > img {
        vertical-align: top;
      }
    }
  }
}

.next,
.previous {
  position: absolute;
  height: 50px;
  width: 60px;
  border: 0;
  padding: 10px;
  background: transparent;
  top: 50%;
  left: 0;
  filter: brightness(0.85);
  transform: translateY(-50%);

  &:hover {
    filter: brightness(0.95);
  }
  &:active {
    filter: brightness(1);
  }

  img {
    height: 100%;
  }
}

.next {
  right: 0;
  left: auto;

  img {
    transform: scaleX(-1);
  }
}
