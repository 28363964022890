.details {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.9);
}

.emptyMsg {
  color: #503c27;
  text-shadow: 0 0 2px #fff;
  font-size: 18px;
  margin: 40px auto;
  text-align: center;
  border-radius: 16px;
  background-color: #ffebd1;
  width: 700px;
  padding: 20px;
  font-weight: 600;
}
