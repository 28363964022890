.list {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &:after {
    content: "";
    flex: auto;
  }
}

.axie {
  box-sizing: border-box;
  width: 25%;
  // border: 1px solid;
  padding: 5px;

  .content {
    padding: 6px;
    position: relative;

    &.selected,
    &:hover {
      border-radius: 10px;
      border: 3px solid #977b5f;
      padding: 3px;

      .favorite {
        top: 1px;
        right: 1px;
      }
    }

    > img {
      width: 100%;
    }

    .name {
      font-family: "Luckiest Guy", sans-serif;
      color: #503c27;
      text-shadow: 0 0 2px #fff;
      font-size: 14px;
      max-width: 82.5%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.noStroke {
        -webkit-text-stroke: 0;
      }

      img {
        vertical-align: bottom;
      }
    }

    .favorite {
      position: absolute;
      top: 4px;
      right: 4px;
      height: 30px;
      width: 32px;
      background: url("~/public/images/ui/star-empty.png") no-repeat;
      background-size: cover;
      opacity: 0.4;

      &.selected {
        background: url("~/public/images/ui/star-full.png") no-repeat;
        opacity: 1;
      }
    }
  }
}

.disabled {
  pointer-events: none;
}
