.controls {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  width: 500px;
  height: 294px;
  background: red;
}

.mine {
  position: absolute;
  height: 412px;
  width: 412px;
  top: 5px;
  left: 50%;
  transform: translateX(-50%);

  img {
    width: 100%;
  }
}

.dummy {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 170px;
  align-items: center;

  > img {
    width: 102px;
  }

  .dummyStat {
    margin-top: 4px;
    height: 60px;
    width: 100%;
    background: green;
  }
}
