.ui {
  position: absolute;
  top: 50%;
  left: 50%;

  * {
    cursor: url("~/public/images/cursor.png"), pointer;
  }
}

.screen {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.box {
  box-sizing: border-box;
  background: linear-gradient(180deg, #bbad94, #b79164);
  padding: 80px 40px;
  border-radius: 24px;
  border: 3px solid #977b5f;
  box-shadow: inset 0 0 0 1px #503c27, 0 0 2px 1px #583c27;
  position: relative;

  &.dark {
    background: linear-gradient(180deg, #866641, #7d5d37);
  }

  &.transparent {
    background: linear-gradient(180deg, #bbad94dd, #b79164dd);
  }
}

.centerHorizontal {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.separator {
  height: 20px;
  display: flex;
  align-items: center;
  padding: 0 5px;

  &::before {
    content: "";
    height: 1px;
    width: 50%;
    background: #583c27;
  }

  &.light::before {
    background: rgba(88, 60, 39, 0.3);
  }

  span {
    color: #4a341b;
    margin: 0 25px;
    align-self: baseline;
  }

  &::after {
    content: "";
    height: 1px;
    width: 50%;
    background: #583c27;
  }

  &.light::after {
    background: rgba(88, 60, 39, 0.3);
  }
}

.blitz {
  font-family: "Luckiest Guy", sans-serif;
  font-weight: 400;
  color: #ffebd1;
  -webkit-text-stroke: 0.5px #423a32;
  text-shadow: 0 0px 2px #423a32;
}

.errorMsg {
  display: none;
  color: rgba(255, 255, 255, 0.9);
  background: rgba(255, 0, 0, 0.8);
  font-weight: 600;
  padding: 5px 5px;
  border-radius: 4px;
  margin-bottom: 2px;
  pointer-events: none;
  margin-bottom: 10px;

  &.showError {
    display: block;
  }
}

.hideScrollbar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
}
