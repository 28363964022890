.button {
  border-radius: 12px;
  font-family: "Luckiest Guy", sans-serif;
  height: 54px;
  width: 100%;
  border: 3px solid #fbcc8a;
  background: #fbcc8a;
  position: relative;
  padding: 0;
  opacity: 1;
  transition: opacity 0.2s;

  &.small {
    border-radius: 8px;
    height: 38px;
    border: 2px solid #977b5f;
    box-shadow: 0 0 1.5px 0.5px #583c27;
  }

  &.inline {
    display: inline-block;
    width: auto;
    padding: 0 24px;
  }

  &:hover {
    filter: brightness(0.95);
  }

  &:active {
    filter: brightness(1.05);
  }

  &[disabled] {
    opacity: 0.4;
  }

  &.plain {
    background: none;
    border: 0;
    cursor: pointer;

    &:hover .copy {
      text-shadow: 0 0px 4px #755930;
      -webkit-text-stroke: 1.5px #68502a;
    }
  }
}

.textButton {
  border: 0;
  background: transparent;
  padding: 0 20px;
  font-family: "Luckiest Guy", sans-serif;
  cursor: pointer;

  .copy {
    color: #73582e;
    text-shadow: 0 0px 2px #4a341b;
    -webkit-text-stroke: 0.5px #ffffff;
  }

  &.large {
    .copy {
      font-size: 32px;
    }
  }
}

.menuButton {
  border: 3px solid #977b5f;
  box-shadow: 0 0 2px 1px #583c27;
}

.roninButton {
  border: 3px solid #1175ff;
  background: #1175ff;

  .copy {
    text-shadow: 0 0px 1.5px #fff;
    color: #003dcc;
    -webkit-text-stroke: 1px rgba(255, 255, 255, 0.75);
  }

  &.size-small {
    height: 32px;
    border-radius: 10px;
    width: auto;
    padding: 0 12px;

    .copy {
      font-size: 13px;
      line-height: 26px;
      text-shadow: 0 0px 1px #755930;
      letter-spacing: 0.5px;
      color: #fff;
      width: auto;
      -webkit-text-stroke: 0;
    }
  }
}

.background {
  position: absolute;
  top: 14px;
  left: 0;
  width: calc(100% - 0.5px);
  height: calc(100% - 14.5px);
  background: #dfb57d;
  overflow: hidden;
  line-height: 0;
  border-radius: 0 0 9px 9px;

  .small & {
    top: 10px;
    height: calc(100% - 10.5px);
    border-radius: 0 0 6px 6px;
  }

  .shapeFill {
    fill: #fbcc8a;
  }
}

.background svg {
  position: relative;
  display: block;
  width: calc(160% + 1.3px);
  height: 28px;
  top: -0.5px;

  .small & {
    height: 20px;
  }
}

.copy {
  font-size: 22px;
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  line-height: 48px;
  text-shadow: 0 0px 2px #755930;
  color: #fff;
  -webkit-text-stroke: 1px #68502a;
  z-index: 1;

  img {
    height: calc(100% - 8px);
    vertical-align: middle;
    margin-right: 5px;
  }

  .small & {
    line-height: 32px;
    font-size: 18px;
    -webkit-text-stroke: 0.5px #68502a;
  }
}
