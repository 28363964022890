.box {
  width: 410px;
  top: 110px;
  padding: 0;
  position: absolute;
  overflow: hidden;
  filter: brightness(0.9);

  img {
    width: 100%;
  }

  &.disabled {
    &:hover {
      filter: brightness(0.9);
    }
  }

  &:hover {
    filter: brightness(1.05);
  }

  &:active {
    filter: brightness(1.15);
  }
}

.disabledOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.65);

  img {
    width: 160px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: grayscale(0.75) brightness(0.8);
    opacity: 0.7;
  }
}

.title {
  color: #fafafa;
  position: absolute;
  left: 50%;
  top: 10px;
  font-size: 60px;
  transform: translateX(-50%);
  text-shadow: 0 0px 5px #b79164;
  -webkit-text-stroke: 2px #977b5f;
}

.gameLabel {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 52px;
  text-shadow: 0 0px 5px #423a32;
  -webkit-text-stroke: 2px #423a32;
}

.back {
  position: absolute;
  height: 60px;
  width: 80px;
  border: 0;
  padding: 10px;
  background: transparent;
  top: 15px;
  left: 10px;
  filter: brightness(0.85);

  &:hover {
    filter: brightness(0.95);
  }
  &:active {
    filter: brightness(1);
  }

  img {
    height: 100%;
  }
}

.selectionWrapper {
  position: absolute;
  bottom: 15px;
  width: 100%;
  height: 120px;
}
