.forgot {
  color: #4a341b;
  font-weight: 600;
  font-size: 12px;
  margin-left: 5px;
  display: inline-block;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.modal {
  background: linear-gradient(180deg, #bbad94, #b79164);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 24px;
  z-index: 2;

  .modalContent {
    margin-top: 60px;
    padding: 0 40px;

    p {
      text-align: center;
    }
  }

  .close {
    border: 0;
    margin: 0;
    padding: 0;
    background: transparent;
    color: #333;
    position: absolute;
    top: 5px;
    right: 5px;
    height: 32px;
    width: 32px;
    cursor: pointer;

    svg {
      height: 100%;
      width: 100%;
    }
  }
}
