.container {
  height: 695px;
  bottom: 15px;
  position: absolute;
  width: 1240px;
  left: 20px;
  padding: 30px 20px 15px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.close {
  border: 0;
  margin: 0;
  padding: 0;
  background: transparent;
  color: #333;
  position: absolute;
  top: 5px;
  right: 15px;
  height: 64px;
  width: 64px;
  cursor: pointer;

  svg {
    height: 100%;
    width: 100%;
  }
}

.playerAxies {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.emptyMsg {
  color: #503c27;
  text-shadow: 0 0 2px #fff;
  font-size: 18px;
  margin: 40px auto;
  text-align: center;
  border-radius: 16px;
  background-color: #ffebd1;
  width: 900px;
  padding: 20px;
  font-weight: 600;
}

.details {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.9);
}
