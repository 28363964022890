.container {
  position: absolute;
  padding: 0 20px;
  height: 120px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  background: rgba(0, 0, 0, 0.35);
  align-items: center;
}

.copy {
  font-size: 32px;
}

.selection {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 20px;
  max-width: 120px;
  align-items: center;

  > img {
    max-height: 84px;
  }

  > span {
    font-size: 18px;
    white-space: nowrap;
    max-width: 400px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: -2px;
  }
}
