@import-normalize;

.App {
  text-align: center;

  * {
    box-sizing: border-box;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#root {
  height: 100vh;

  .game-container {
    background-image: url("../public/background.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: 100%;
    justify-content: center;

    > div {
      flex: 1;
      width: 100%;
      overflow: hidden;
      position: relative;

      &.size-default {
        max-width: 1440px;
      }

      &.size-100 {
        max-width: 100%;
      }

      &.size-1920 {
        max-width: 1920px;
      }
    }
  }
}

.tr {
  &-fade {
    &-enter {
      opacity: 0;

      &-active {
        opacity: 1;
        transition: opacity 0.5s;
      }
    }
    &-exit {
      opacity: 1;

      &-active {
        opacity: 0;
        transition: opacity 0.5s;
      }
    }
  }

  &-fade-up {
    &-enter {
      opacity: 0;
      transform: translateY(100%);

      &-active {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 0.5s, transform 0.2s;
      }
    }
    &-exit {
      opacity: 1;
      transform: translateY(0);

      &-active {
        opacity: 0;
        transform: translateY(-100%);
        transition: opacity 0.5s, transform 0.2s;
      }
    }
  }

  &-fade-up-fixed {
    &-enter {
      opacity: 0;
      transform: translateY(100%);

      &-active {
        opacity: 1;
        transform: translateY(0);
        transition: 0.35s opacity 0.55s cubic-bezier(0.53, 1.36, 0.53, 0.95),
          0.5s transform 0.4s cubic-bezier(0.53, 1.36, 0.53, 0.95);
      }
    }
    &-exit {
      opacity: 1;

      &-active {
        opacity: 0;
        transition: opacity 0.5s, transform 0.2s;
      }
    }
  }

  &-fade-left {
    &-enter {
      opacity: 0;
      transform: translateX(-120%);

      &-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 0.5s, transform 0.4s;
      }
    }
    &-exit {
      opacity: 1;
      transform: translateX(0);

      &-active {
        opacity: 0;
        transform: translateX(-120%);
        transition: opacity 0.5s, transform 0.4s;
      }
    }
  }

  &-fade-right {
    &-enter {
      opacity: 0;
      transform: translateX(120%);

      &-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 0.5s, transform 0.4s;
      }
    }
    &-exit {
      opacity: 1;
      transform: translateX(0);

      &-active {
        opacity: 0;
        transform: translateX(120%);
        transition: opacity 0.5s, transform 0.4s;
      }
    }
  }

  &-fade-left-bounce-fixed {
    &-enter {
      opacity: 0;
      transform: translateX(-120%);

      &-active {
        opacity: 1;
        transform: translateX(0);
        transition: 0.35s opacity 0.55s cubic-bezier(0.53, 1.36, 0.53, 0.95),
          0.5s transform 0.4s cubic-bezier(0.53, 1.36, 0.53, 0.95);
      }
    }
    &-exit {
      opacity: 1;
      // transform: translateX(0);

      &-active {
        opacity: 0;
        // transform: translateX(-120%);
        transition: opacity 0.5s;
      }
    }
  }

  &-fade-right-bounce-fixed {
    &-enter {
      opacity: 0;
      transform: translateX(120%);

      &-active {
        opacity: 1;
        transform: translateX(0);
        transition: 0.35s opacity 0.55s cubic-bezier(0.53, 1.36, 0.53, 0.95),
          0.5s transform 0.4s cubic-bezier(0.53, 1.36, 0.53, 0.95);
      }
    }
    &-exit {
      opacity: 1;
      // transform: translateX(0);

      &-active {
        opacity: 0;
        // transform: translateX(-120%);
        transition: opacity 0.5s;
      }
    }
  }

  &-bounce {
    &-z {
      &-enter {
        transform: scale(0.2);
        opacity: 0;

        &-active {
          opacity: 1;
          transform: scale(1);
          transition: 0.35s opacity cubic-bezier(0.61, 1.56, 0.62, 0.9) 0.15s,
            transform 0.5s;
        }
      }

      // &-exit {
      //   opacity: 1;

      //   &-active
      // }
    }
  }
}
