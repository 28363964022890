.leftBar {
  top: 20px;
  left: 10px;
  width: 400px;
  height: calc(100% - 40px);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.mainBox {
  right: 10px;
  top: 20px;
  position: absolute;
  width: 850px;
  height: calc(100% - 40px);
  padding: 0;
  overflow: hidden;
}

.option {
  text-shadow: 0 0px 4px #4a341b !important;
  -webkit-text-stroke: 1px #ffffff !important;
  transform: scale(1);
  transition: all 0.2s;
}

.selected {
  text-shadow: 0 0px 14px #4a341b !important;
  -webkit-text-stroke: 2px #ffffff !important;
  transform: scale(1.25);
}

.optionsContainer {
  box-sizing: border-box;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  padding: 80px 40px;
}

.optionLabelContainer {
  color: #ffffff;
  font-family: "Luckiest Guy", sans-serif;
  font-size: 24px;
  -webkit-text-stroke: 0.5px #423a32;
  text-shadow: 0 0px 2px #423a32;
  margin-bottom: 24px;
}

.optionLabel {
  margin-left: 16px !important;
}

.ctas {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: auto;

  > button {
    width: 45%;
  }
}

.dropdown {
  -webkit-text-stroke: 0px #423a32;
  margin-top: 5px;
}

.track {
  height: 16px;
  border-radius: 5px;
  top: 4px;

  &0 {
    background: #996147;
  }

  &1 {
    background: rgba(153, 97, 71, 0.4);
  }
}

.sliderValue {
  position: absolute;
  top: 26px;
  right: 0;
  transform: translateX(calc(100% + 24px));
}
