.back {
  position: absolute;
  height: 60px;
  width: 80px;
  border: 0;
  padding: 10px;
  background: transparent;
  top: 15px;
  left: 10px;
  filter: brightness(0.85);

  &:hover {
    filter: brightness(0.95);
  }
  &:active {
    filter: brightness(1);
  }

  img {
    height: 100%;
  }
}

.players {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -20%);
  text-align: center;
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.61, 1.56, 0.62, 0.9) 1.5s,
    transform 0.4s cubic-bezier(0.61, 1.56, 0.62, 0.9) 1.5s;

  .showContent & {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

.count {
  margin-top: 72px;
  font-size: 82px;
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.61, 1.56, 0.62, 0.9) 2.25s;

  .showContent & {
    opacity: 1;
  }
}

.loader {
  position: ABSOLUTE;
  width: 600px;
  height: 600px;
  display: block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.8);
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.61, 1.56, 0.62, 0.9) 1s;

  .showContent & {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.loader:before,
.loader:after {
  content: "";
  border-radius: 50%;
  position: absolute;
  inset: 0;
  box-shadow: 0 0 10px 1px rgb(0 0 0 / 10%) inset;
}
.loader:after {
  box-shadow: 0 4px 2px #b79164 inset;
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
